<template>
    <div id="company">
        <!-- <p>{{ message }}</p> -->
    </div>
</template>

<script>
export default {
    name: "Company",
    data: () => {
        return {
            message: "Company message"
        }
    },
    created() {
        console.log('Company created');
    }
}
</script>

<style lang="scss" scoped>
p {
    font-size: 1em;
    text-align: center;
}
</style>
