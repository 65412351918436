<template>
    <div id="dashboard">
        <!-- <p>{{ message }}</p>
        <p>{{ dashboard_data.name }}</p>
        <p>{{ dashboard_data.number }}</p> -->
        <Company></Company>
    </div>
</template>

<script>
import axios from 'axios'
import Company from './company.vue'
export default {
    name: "Dashboard",
    components: { Company },
    data: () => {
        return {
            message: "Dashboard message",
            dashboard_data: {}
        }
    },
    created() {
        console.log('Dashboard created')
        axios
            .get('/dashboard/index.json')
            .then(response => (this.dashboard_data = response.data))
    }
}
</script>

<style lang="scss" scoped>
p {
    font-size: 2em;
    text-align: center;
}
</style>
